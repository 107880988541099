import VendorsIndex from './VendorsIndex';
import VendorsCreate from './VendorsCreate';
import VendorsSettings from './VendorsSettings';
import VendorsSingle from './VendorsSingle';

export default function (app) {

    const settings = VendorsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 12,
        items: [
            {
                label: "All Vendors",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Active",
            //     path: settings.path + "/status/active",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Inactive",
            //     path: settings.path + "/status/inactive",
            //     caps: settings.indexCaps
            // },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.indexCaps
            }
        ],
    });

    VendorsIndex(app, settings);

    VendorsCreate(app, settings);

    VendorsSingle(app, settings);

}