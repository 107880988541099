import React, { useMemo } from 'react';
import { Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import { Row } from '@dex/bubl-dash';

const UsersCreateEditForm: React.FC<any> = (props: any) => {

    const { data, form } = props;

    return useMemo(() => (

        <>

            <Row gutter={8} edge={true}>

                {form.values.roles?.includes('vendor') &&
                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label='Vendor'
                            name='vendorId'
                            required={true}
                            preload={true}
                            endpoint={'Vendors'}
                            defaultValue={form.defaults.vendorId}
                        />

                    </Col>
                }

                {form.values.roles?.includes('merchant') &&
                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label='Merchant'
                            name='merchantId'
                            required={true}
                            preload={true}
                            endpoint={'Merchants'}
                            defaultValue={form.defaults.merchantId}
                        />

                    </Col>
                }

            </Row>

        </>

    ), [data, form]);

}

export default UsersCreateEditForm;
