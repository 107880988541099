import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Space } from '@dex/bubl-dash';
import { FieldTextArea } from '@dex/bubl-dash';
import { FieldCheckbox } from '@dex/bubl-dash';

const StandingInstructionsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Instruction Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={"Merchant"}
                            name='merchantId'
                            required={true}
                            preload={true}
                            endpoint={'Merchants'}
                            defaultValue={form.defaults.merchantId}
                            disabled={mode === "edit"}
                        />

                        <Space height={"small"} />

                        <FieldRelationship
                            form={form}
                            label={"Product"}
                            name='productId'
                            required={true}
                            preload={true}
                            endpoint={'Products'}
                            defaultValue={form.defaults.productId}
                        />

                        <Space height={"small"} />

                        <FieldTextArea
                            form={form}
                            label={"Remarks"}
                            name='remarks'
                            defaultValue={form.defaults.remarks}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            button
                            form={form}
                            label={"Batch"}
                            name='batch'
                            required={true}
                            defaultValue={form.defaults.batch}
                            options={[
                                { label: "Batch 1-14th", value: "batch 1-14th" },
                                { label: "Batch 2-28th", value: "batch 2-28th" }
                            ]}
                        />

                        <Space height={"small"} />

                        <FieldRadio
                            form={form}
                            label={"Status"}
                            name='status'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" }
                            ]}
                        />

                        <Space height={"small"} />

                        <FieldCheckbox
                            form={form}
                            label={"Months"}
                            name='months'
                            required={true}
                            isMulti={true}
                            defaultValue={form.defaults.months}
                            options={[
                                { label: "January", value: "1" },
                                { label: "February", value: "2" },
                                { label: "March", value: "3" },
                                { label: "April", value: "4" },
                                { label: "May", value: "5" },
                                { label: "June", value: "6" },
                                { label: "July", value: "7" },
                                { label: "August", value: "8" },
                                { label: "September", value: "9" },
                                { label: "October", value: "10" },
                                { label: "November", value: "11" },
                                { label: "December", value: "12" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default StandingInstructionsCreateEditForm;
