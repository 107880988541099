import React, { useMemo, useCallback } from 'react';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import RequestsApi from '../../RequestsApi';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { FieldRadio } from '@dex/bubl-dash';
import { FieldTextArea } from '@dex/bubl-dash';

const RequestCancellationForm: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store('nav');

    const form = useForm({ "cancelStatus": data.cancelStatus });

    const process = RequestsApi.update(data.id, 'cancelRequest', {
        onComplete: (data) => {

            app.navigate(nav, 'update', {});

        }
    });

    const handleSubmit = useCallback((values) => {

        process.run({
            data: values
        });

    }, [])

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldRadio
                        form={form}
                        label={"Cancel"}
                        name='cancelStatus'
                        defaultValue={form.values.cancelStatus}
                        options={[
                            { value: "yes", label: "Yes" },
                            { value: "no", label: "No" },
                        ]}
                        required={true}
                    />

                </Col>

                {form.values.cancelStatus == "yes" &&
                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Cancel Notes"}
                            name='cancelNotes'
                            defaultValue={form.values.cancelNotes}
                            required={true}
                        />

                    </Col>
                }

            </Row>

            <Line />

            <ButtonRow>
                <Button
                    label={"Submit"}
                    loading={process.loading}
                />
                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={form.reset}
                />
            </ButtonRow>

        </Form>

    ), [data, form, process]);

}

export default RequestCancellationForm;
