import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import RequestsApi from '../RequestsApi';
import RequestCancellationView from './RequestCancellation/RequestCancellationView';
import { Space } from '@dex/bubl-dash';
import RequestsCreateFormValidate from './RequestsCreateFormValidate';
import { Panel } from '@dex/bubl-dash';

const RequestsSingleEdit: React.FC<any> = (props: any) => {

    const app = useApp();

    const { data } = props;

    const form = useForm(data);

    const update = RequestsApi.update(data.id, "update", {
        onComplete: (data) => {

            app.alert('Request Saved', 'success');

        }
    });

    const handleSubmit = useCallback((values) => {

        update.run({ data: values });

    }, [props]);

    console.log(data.attachment);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                {(!data.shippedOn) &&

                    <Panel
                        heading={"Request Details"}
                    >

                        <RequestsCreateFormValidate
                            form={form}
                            merchant={data}
                            process={update}
                            handleSubmit={handleSubmit}
                            handleReset={form.reset}
                            mode={"edit"}
                        />

                    </Panel>
                }

                {(!data.shippedOn || data.status == "returned") &&
                    <>

                        <Space />

                        <RequestCancellationView
                            data={data}
                        />

                    </>
                }

            </Col>

        </Row>

    ), [data, form, update]);

}

export default RequestsSingleEdit;
