import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { utils, writeFile } from 'xlsx';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import MerchantsApi from '../MerchantsApi';
import MerchantsImportCheckForm from './MerchantsImportCheckForm';
import MerchantsImportValidateForm from './MerchantsImportValidateForm';
import MerchantsImportReviewForm from './MerchantsImportReviewForm';

const MerchantsImportForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;
    const [step, setStep] = useState("check");

    // eslint-disable-next-line
    let data = props.data || {};

    const merchant = MerchantsApi.create({}, 'check');

    const handleCheck = useCallback((values) => {

        merchant.run({
            data: values
        })

    }, []);


    useEffect(() => {

        let step = "check";

        if (merchant.data) step = "validate";
        if (process.data) step = "review";

        setStep(step);

    }, [merchant.loading, process.loading])

    const handleReset = useCallback(() => {

        form.reset();
        process.reset();
        merchant.reset();

        setStep("check");

    }, []);

    const columns = [
        {
            label: "MID",
            key: "mid",
        },
        {
            label: "Company Name",
            key: "companyName",
        },
        {
            label: "Trading Name",
            key: "tradingName",
        },
        {
            label: "Number of Rolls",
            key: "numberOfRolls",
        },
        {
            label: "Limit Per Month",
            key: "limitPerMonth",
        },
        {
            label: "Status",
            key: "status",
        },
        {
            label: "Remarks",
            key: "remarks",
        },
        {
            label: "Email Address",
            key: "email",
            style: { minWidth: 160 }
        },
        {
            label: "Phone Number",
            key: "phone",
            style: { minWidth: 200 }
        },
        {
            label: "Shipping Address Line 1",
            key: "line1",
            style: { minWidth: 160 }
        },
        {
            label: "Shipping Address Line 2",
            key: "line2",
            style: { minWidth: 160 }
        },
        {
            label: "Zip Code",
            key: "zipcode",
            style: { minWidth: 160 }
        },
        {
            label: "City",
            key: "city",
            style: { minWidth: 160 }
        },
        {
            label: "State",
            key: "state",
            style: { minWidth: 160 }
        },
        {
            label: "Country",
            key: "country",
            style: { minWidth: 160 }
        },
    ];

    const exportCsv = () => {

        const worksheet = utils.json_to_sheet([{}], { header: columns.map(item => item.label) });

        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheet, 'Sheet');

        writeFile(workbook, 'Merchants-Import.xlsx');

    };

    return useMemo(() => (

        <Panel
            heading={"Import Merchants"}
            actions={(
                <Button
                    label={"Create Blank File"}
                    size={"small"}
                    onClick={exportCsv}
                />
            )}
        >

            {step === "check" && (

                <MerchantsImportCheckForm
                    columns={columns}
                    form={form}
                    process={merchant}
                    handleSubmit={handleCheck}
                    handleReset={handleReset}
                />

            )}

            {step === "validate" && (

                <MerchantsImportValidateForm
                    form={form}
                    merchants={merchant.data}
                    process={process}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                />

            )}

            {step === "review" && (

                <MerchantsImportReviewForm
                    merchants={process.data}
                    handleReset={handleReset}
                />

            )}

        </Panel>

    ), [form.hash, step, merchant.loading, process.loading]);

}

export default MerchantsImportForm;
