import { baseApi, options, response } from "@dex/bubl-fetch";

const endpoint = 'StandingInstructions';

const StandingInstructionsApi: any = baseApi(endpoint);

StandingInstructionsApi.import = (options?: options): response => {

    return StandingInstructionsApi.create(options, "import");

}

export default StandingInstructionsApi;