import MerchantsIndex from './MerchantsIndex';
import MerchantsCreate from './MerchantsCreate';
import MerchantsSettings from './MerchantsSettings';
import MerchantsSingle from './MerchantsSingle';

export default function (app) {

    const settings = MerchantsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 10,
        items: [
            {
                label: "All Merchants",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Active",
            //     path: settings.path + "/status/active",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Inactive",
            //     path: settings.path + "/status/inactive",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Limit Reached",
            //     path: settings.path + "/status/limitReached",
            //     caps: settings.indexCaps
            // },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
            {
                label: "Import Multiple",
                path: settings.path + "/import",
                caps: settings.createCaps
            },

        ],
    });

    MerchantsIndex(app, settings);

    MerchantsCreate(app, settings);

    MerchantsSingle(app, settings);

}