import React, { useCallback } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Space } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';
import { FieldHidden } from '@dex/bubl-dash';
import { Alert } from '@dex/bubl-dash';

const MerchantsImportValidateForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, merchants, handleReset } = props;

    const columns = [
        {
            label: "Message",
            key: "message",
        },
        {
            label: "MID",
            key: "mid",
        },
        {
            label: "Company Name",
            key: "companyName",
        },
        {
            label: "Trading Name",
            key: "tradingName",
        },
        {
            label: "Number Of Rolls",
            key: "numberOfRolls",
            format: "number"
        },
        {
            label: "Limit Per Month",
            key: "limitPerMonth",
            format: "number"
        },
        {
            label: "Status",
            key: "status",
            format: "titleCase"
        },
        {
            label: "Remarks",
            key: "remarks",
        },
        {
            label: "Email Address",
            key: "email",
            style: { minWidth: 160 }
        },
        {
            label: "Phone Number",
            key: "phone",
            style: { minWidth: 200 }
        },
        {
            label: "Shipping Address Line 1",
            key: "line1",
            style: { minWidth: 160 }
        },
        {
            label: "Shipping Address Line 2",
            key: "line2",
            style: { minWidth: 160 }
        },
        {
            label: "Zip Code",
            key: "zipcode",
            style: { minWidth: 160 }
        },
        {
            label: "City",
            key: "city",
            style: { minWidth: 160 }
        },
        {
            label: "State",
            key: "state",
            style: { minWidth: 160 }
        },
        {
            label: "Country",
            key: "country",
            style: { minWidth: 160 }
        },
    ];

    // eslint-disable-next-line
    let data = props.data || {};

    const getClassByStatus = useCallback((row) => {

        return row.valid ? "" : "table-bg-yellow";

    }, []);

    return (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <FieldHidden
                form={form}
                name={'merchants'}
                defaultValue={form.values.merchants}
            />

            <FieldHidden
                form={form}
                name={'productId'}
                defaultValue={form.values.productId}
            />

            <FieldHidden
                form={form}
                name={'vendorId'}
                defaultValue={form.values.vendorId}
            />

            {merchants.success > 0 &&
                <>
                    <Alert
                        type={"info"}
                        message={merchants.success + " Merchants Found."}
                        description={"Click submit below to generate merchant requests."}
                    />
                    <Space />
                </>
            }

            {merchants.error > 0 &&
                <>
                    <Alert
                        type={"warning"}
                        description={merchants.error + " Errors Occurred. Please check the list below."}
                    />
                    <Space />
                </>
            }

            <DataTable
                data={merchants.list}
                columns={columns}
                rowClassName={getClassByStatus}
            />

            <Space />

            <ButtonRow>

                <Button
                    label={"Import Merchants"}
                    loading={process.loading}
                />

                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={handleReset}
                />

            </ButtonRow>

        </Form>

    );
}

export default MerchantsImportValidateForm;
