import React, { useMemo, useState } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import { useApp } from '@dex/bubl-dash';
import RequestCancellationForm from './RequestCancellationForm';

const RequestCancellationView: React.FC<any> = (props: any) => {

    const { data } = props;

    const [showForm, setShowForm] = useState(data.status == "cancelled" ? false : true);

    const app = useApp();

    const canReview = app.userCan(["reviewRequests"]);

    return useMemo(() => (

        <Panel
            heading={"Cancel Request?"}
            actions={data.cancelledOnBy ? (
                <Button
                    label={showForm ? "Cancel" : "Edit"}
                    size="small"
                    type="inline"
                    onClick={() => { setShowForm(!showForm) }}
                />
            ) : null}
        >

            {data.status == "cancelled" &&

                <MetaTable
                    data={data}
                    columnA={[
                        {
                            label: "Status",
                            key: "status",
                        },
                        {
                            label: "Cancel Notes",
                            key: "cancelNotes",
                        },
                    ]}
                    columnC={[
                        {
                            label: "Last Updated On",
                            key: "cancelledOnBy",
                            format: "onAndBy",
                        },
                    ]}
                />

            }

            {showForm && canReview &&
                <>
                    <Line />
                    <RequestCancellationForm
                        data={data}
                    />
                </>
            }

        </Panel>

    ), [data, showForm]);

}

export default RequestCancellationView;
