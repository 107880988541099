import React, { useMemo } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import RequestsReviewView from './RequestReview/RequestReviewView';
import RequestsShippingView from './RequestShipping/RequestShippingView';
import RequestsDeliveryView from './RequestsDelivery/RequestsDeliveryView';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import { Line } from '@dex/bubl-dash';
import { isEmpty } from '@dex/bubl-helpers';

const RequestsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Request Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Merchant",
                                key: "merchant",
                                format: "modelLink",
                            },
                            {
                                label: "Requested On",
                                key: "created.date",
                                format: "date",
                            },
                            {
                                label: "Acquirers Review Needed",
                                key: "reviewNeeded",
                                format: "switch"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Product",
                                key: "productMeta.name",
                            },
                            {
                                label: "Number of Rolls",
                                key: "productMeta.numberOfRolls",
                                format: "number"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Remarks",
                                key: "remarks",
                                format: "nl2br"
                            },
                            !isEmpty(data.attachment) && {
                                label: "Attachment",
                                key: "attachment",
                                format: "file",
                                hideEmpty: true
                            },
                        ].filter(Boolean)}
                    />

                    <Line />

                    <MetaTable
                        data={data.merchant}
                        columnA={[
                            {
                                label: "Company Name",
                                key: "companyName",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Phone Number",
                                key: "phone",
                            },
                            {
                                label: "Email Address",
                                key: "email",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Address",
                                key: "address",
                                format: "address"
                            },
                        ]}
                    />

                    {data.instructionId &&

                        <>

                            <Line />

                            <MetaTable
                                data={data.instruction}
                                columnA={[
                                    {
                                        label: "Standing Instruction",
                                        key: "this",
                                        format: "modelLink",
                                    },
                                ]}
                                columnB={[
                                    {
                                        label: "Batch",
                                        key: "batch",
                                        format: "titleCase"
                                    },
                                ]}
                                columnC={[
                                ]}
                            />

                        </>

                    }

                </Panel>

                {data.reviewNeeded &&
                    <>

                        <Space />

                        <RequestsReviewView
                            data={data}
                        />

                    </>
                }

                {(!data.reviewNeeded || (data.reviewNeeded && data.reviewStatus === "approved")) && data.status !== "cancelled" &&
                    <>

                        <Space />
                        <RequestsShippingView
                            data={data}
                        />

                        {data.shippingStatus === "shipped" &&
                            <>
                                <Space />
                                <RequestsDeliveryView
                                    data={data}
                                />
                            </>
                        }

                    </>
                }

            </Col>

            <Col
                className={'side'}
            >

                <ActivityPanel
                    parent={data}
                />

            </Col>

        </Row>

    ), [data, data.productMeta?.name]);

}

export default RequestsSingleView;
