import React, { useCallback } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { FieldText, FieldTextArea, FieldUpload, Space, Alert, FieldHidden, useMountEffect } from '@dex/bubl-dash';
import { FieldRelationship } from '@dex/bubl-dash';
import RequestsApi from '../RequestsApi';

const RequestsCreateFormValidate: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, handleReset, merchant, mode } = props;


    return (
        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >


            {merchant.existing == true &&
                <>
                    <Alert
                        type={"warning"}
                        description={"Merchant has pending request. Are you sure you want to add another?"}
                    />
                    <Space />
                </>
            }

            {mode !== "edit" &&
                <FieldHidden
                    form={form}
                    name='incomingType'
                    defaultValue={"manual"}
                />
            }

            <FieldRelationship
                form={form}
                label={"Merchant MID's"}
                name='merchantId'
                required={true}
                endpoint={'Merchants'}
                defaultValue={form.values.merchantId}
                disabled
                hidden
            />

            <FieldRelationship
                key={form.defaults.productId}
                form={form}
                name='productId'
                defaultValue={form.defaults.productId}
                endpoint='Products'
                label='Product'
                preload
                disabled={mode == "edit"}
            />

            <FieldTextArea
                form={form}
                name='remarks'
                defaultValue={form.defaults.remarks}
                label='Remarks'
            />

            <FieldUpload
                form={form}
                folder={'uploads'}
                label={"Attachment"}
                name={`attachment`}
                required={false}
                defaultValue={form.defaults.attachment}
                limit={1}
            />

            <Space />

            <ButtonRow>
                <Button
                    label={"Submit Merchant Request"}
                    loading={process.loading}
                    confirm={merchant.existing == true ? {
                        title: `Merchant has pending request.`,
                        message: "Are your sure you want to perform this action?",
                    } : null}
                    onClick={form.handleSubmit.bind(null, handleSubmit)}
                />

                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={handleReset}
                />
            </ButtonRow>

        </Form>

    )
}

export default RequestsCreateFormValidate;
