import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import StandingInstructionsCreateEditForm from './Components/StandingInstructionsCreateEditForm';
import StandingInstructionsApi from './StandingInstructionsApi';
import StandingInstructionsRunBatch from './Components/StandingInstructionsRunBatch';
import StandingInstructionsImportForm from './Components/StandingInstructionsImportForm';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New Standing Instruction",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: StandingInstructionsCreateEditForm,
                getCreate: StandingInstructionsApi.create,
                defaults: { status: "active" },
                successMessage: "Standing Instruction Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

    app.addRoute({
        name: settings.key + "Import",
        zone: settings.zone,
        path: settings.key + "/import",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Import Instructions",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: StandingInstructionsImportForm,
                getCreate: StandingInstructionsApi.import,
                defaults: {},
                successMessage: "Instructions Saved",
                successRouteName: settings.key,
                idKey: settings.idKey
            }
        },
    });

    app.addRoute({
        name: settings.key + "Run",
        zone: settings.zone,
        path: settings.key + "/batch",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Run Batch",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: StandingInstructionsRunBatch,
                getCreate: StandingInstructionsApi.create,
                handler: "runBatch",
                defaults: {},
                successMessage: "Batch Run Completed",
                idKey: settings.idKey
            }
        },
    });

}