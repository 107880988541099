import React, { useMemo, } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, FieldParseFile, InputTableCell, InputTableRow } from '@dex/bubl-dash';
import { Row } from '@dex/bubl-dash';
import { Line } from '@dex/bubl-dash';
import { loop, slug } from '@dex/bubl-helpers';
import { DataTable } from '@dex/bubl-dash';
import { FieldText } from '@dex/bubl-dash';
import { Space } from '@dex/bubl-dash';
import { FieldSelect } from '@dex/bubl-dash';


const StandingInstructionsImportCheckForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, columns, handleReset } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const parseData = async (data, file, buffer) => {

        const rows: any = [];

        const batchMap = {
            "1": "batch 1-14th",
            "2": "batch 2-28th",
            "batch1": "batch 1-14th",
            "batch2": "batch 2-28th",
            "batch 1": "batch 1-14th",
            "batch 2": "batch 2-28th",
        }

        const validStatus = ["active", "inactive"];

        loop(data, (row, index) => {

            row['batch'] = (row['batch'] || "1").toLowerCase();
            row['batch'] = batchMap[row['batch']] || "";

            row['status'] = (row['status'] || "active").toLowerCase();

            if (!validStatus.includes(row['status'])) {
                row['status'] = '';
            }

            rows.push({
                mid: row['mid'],
                numberOfRolls: row['number-of-rolls'],
                batch: row['batch'],
                status: row['status'],
                months: row['months'],
                remarks: row['remarks'],
            });

        });

        form.reset({ instructions: rows });
        form.setKey();

    };

    return useMemo(() => (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24 }}>

                    <FieldParseFile
                        label={"Import File"}
                        required={true}
                        accept={".xlsx,.xls,.csv"}
                        onFileLoaded={parseData}
                        optsCSV={{
                            header: true,
                            dynamicTyping: false,
                            skipEmptyLines: true,
                            transformHeader: (text, index) => slug(text),
                        }}
                        optsXLS={{
                            toCSV: true,
                            singleSheet: true,
                        }}
                    />

                </Col>

            </Row>

            {form.values.instructions && form.values.instructions.length > 0 &&

                <div style={{ overflow: "auto" }}>

                    <DataTable
                        data={null}
                        columns={columns}
                        className={"input-table"}
                        style={{ overflowY: "scroll" }}
                    >

                        {form.values.instructions.map((instruction, index) => (

                            <InputTableRow key={index}>

                                <InputTableCell style={{ minWidth: "130px" }}>

                                    <FieldText
                                        form={form}
                                        name={`instructions[${index}].mid`}
                                        defaultValue={instruction.mid}
                                        required={true}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "80px" }}>

                                    <FieldText
                                        form={form}
                                        name={`instructions[${index}].numberOfRolls`}
                                        defaultValue={instruction.numberOfRolls}
                                        required={true}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "180px" }}>

                                    <FieldSelect
                                        form={form}
                                        name={`instructions[${index}].batch`}
                                        defaultValue={instruction.batch}
                                        required={true}
                                        options={[
                                            { value: "batch 1-14th", label: "Batch 1-14th" },
                                            { value: "batch 2-28th", label: "Batch 2-28th" }
                                        ]}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "150px" }}>

                                    <FieldSelect
                                        form={form}
                                        name={`instructions[${index}].status`}
                                        defaultValue={instruction.status}
                                        required={true}
                                        options={[
                                            { value: "active", label: "Active" },
                                            { value: "inactive", label: "Inactive" }
                                        ]}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "150px" }}>

                                    <FieldText
                                        form={form}
                                        name={`instructions[${index}].months`}
                                        defaultValue={instruction.months}
                                        required={false}
                                    />

                                </InputTableCell>

                                <InputTableCell style={{ minWidth: "150px" }}>

                                    <FieldText
                                        form={form}
                                        name={`instructions[${index}].remarks`}
                                        defaultValue={instruction.remarks}
                                    />

                                </InputTableCell>

                            </InputTableRow>

                        ))}

                        <Space height={300} />

                    </DataTable>

                </div>

            }

            <Line />

            {form.values.instructions && form.values.instructions.length > 0 && (

                <ButtonRow>

                    <Button
                        label={"Check instructions"}
                        loading={process.loading}
                    />

                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={handleReset}
                    />

                </ButtonRow>

            )}

        </Form>


    ), [form.hash, form.changed, process.loading]);

}

export default StandingInstructionsImportCheckForm;
