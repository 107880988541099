import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { utils, writeFile } from 'xlsx';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import StandingInstructionsApi from '../../StandingInstructions/StandingInstructionsApi';
import StandingInstructionsImportCheckForm from './StandingInstructionsImportCheckForm';
import StandingInstructionsImportValidateForm from './StandingInstructionsImportValidateForm';
import StandingInstructionsImportReviewForm from './StandingInstructionsImportReviewForm';

const StandingInstructionsImportForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;
    const [step, setStep] = useState("check");

    // eslint-disable-next-line
    let data = props.data || {};

    const instruction = StandingInstructionsApi.create({}, 'check');

    const handleCheck = useCallback((values) => {

        instruction.run({
            data: values
        })

    }, []);


    useEffect(() => {

        let step = "check";

        if (instruction.data) step = "validate";
        if (process.data) step = "review";

        setStep(step);

    }, [instruction.loading, process.loading])

    const handleReset = useCallback(() => {

        form.reset();
        process.reset();
        instruction.reset();

        setStep("check");

    }, []);

    const columns = [
        {
            label: "MID",
            key: "mid",
        },
        {
            label: "Number Of Rolls",
            key: "numberOfRolls",
        },
        {
            label: "Batch",
            key: "batch",
        },
        {
            label: "Status",
            key: "status",
        },
        {
            label: "Months",
            key: "months",
        },
        {
            label: "Remarks",
            key: "remarks",
        },
    ];

    const exportCsv = () => {

        const worksheet = utils.json_to_sheet([{}], { header: columns.map(item => item.label) });

        const workbook = utils.book_new();

        utils.book_append_sheet(workbook, worksheet, 'Sheet');

        writeFile(workbook, 'StandingInstructions-Import.xlsx');

    };

    return useMemo(() => (

        <Panel
            heading={"Import Standing Instructions"}
            actions={(
                <Button
                    label={"Create Blank File"}
                    size={"small"}
                    onClick={exportCsv}
                />
            )}
        >

            {step === "check" && (

                <StandingInstructionsImportCheckForm
                    columns={columns}
                    form={form}
                    process={instruction}
                    handleSubmit={handleCheck}
                    handleReset={handleReset}
                />

            )}

            {step === "validate" && (

                <StandingInstructionsImportValidateForm
                    form={form}
                    instructions={instruction.data}
                    process={process}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                />

            )}

            {step === "review" && (

                <StandingInstructionsImportReviewForm
                    StandingInstructions={process.data}
                    handleReset={handleReset}
                />

            )}

        </Panel>

    ), [form.hash, step, instruction.loading, process.loading]);

}

export default StandingInstructionsImportForm;
