import BulkRequestsIndex from './BulkRequestsIndex';
import BulkRequestsCreate from './BulkRequestsCreate';
import BulkRequestsSettings from './BulkRequestsSettings';
import BulkRequestsSingle from './BulkRequestsSingle';

export default function (app) {

    const settings = BulkRequestsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 13,
        items: [
            {
                label: "All Vendor Requests",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Review",
                path: settings.path + "/status/review",
                caps: settings.indexCaps
            },
            {
                label: "Processing",
                path: settings.path + "/status/processing",
                caps: settings.indexCaps
            },
            {
                label: "Shipping",
                path: settings.path + "/status/shipping",
                caps: settings.indexCaps
            },
            {
                label: "Delivered",
                path: settings.path + "/status/delivered",
                caps: settings.indexCaps
            },
            {
                label: "Returned",
                path: settings.path + "/status/returned",
                caps: settings.indexCaps
            },
            {
                label: "Denied",
                path: settings.path + "/status/denied",
                caps: settings.indexCaps
            },
            {
                label: "Cancelled",
                path: settings.path + "/status/cancelled",
                caps: settings.indexCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.indexCaps
            }
        ],
    });

    BulkRequestsIndex(app, settings);

    BulkRequestsCreate(app, settings);

    BulkRequestsSingle(app, settings);

}