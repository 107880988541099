import StandingInstructionsIndex from './StandingInstructionsIndex';
import StandingInstructionsCreate from './StandingInstructionsCreate';
import StandingInstructionsSettings from './StandingInstructionsSettings';
import StandingInstructionsSingle from './StandingInstructionsSingle';

export default function (app) {

    const settings = StandingInstructionsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 13,
        items: [
            {
                label: "All Standing Instructions",
                path: settings.path,
                caps: settings.indexCaps
            },
            process.env.REACT_APP_ENV === "production" ? null : {
                label: "Run Batch",
                path: settings.path + "/batch",
                caps: settings.createCaps
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
            {
                label: "Import Multiple",
                path: settings.path + "/import",
                caps: settings.createCaps
            },
        ].filter(Boolean),
    });

    StandingInstructionsIndex(app, settings);

    StandingInstructionsCreate(app, settings);

    StandingInstructionsSingle(app, settings);

}