import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import VendorsCreateEditForm from './Components/VendorsCreateEditForm';
import VendorsApi from './VendorsApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: VendorsCreateEditForm,
                getCreate: VendorsApi.create,
                defaults: {
                    status: "active",
                    limitPerMonth: 20000,
                    address: { country: "Malaysia" }
                },
                successMessage: "Vendors Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}